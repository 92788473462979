.back-to-top {
  display: none;
  position: fixed;
  bottom: 120px;
  right: 30px;
  width: 4.8rem;
  height: 4.8rem;
  cursor: pointer;
  z-index: 999;
  background: #005f4b;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  &::before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    border-color: #fff;
    content: '';
    display: inline-block;
    height: 1.6rem;
    width: 1.6rem;
    position: relative;
    left: 0;
    top: 2px;
    transform: rotate(-45deg);
    vertical-align: top;
  }

  &.show {
    display:flex;
  }
}
