.btn:not(.btn-link):not(.btn-tag):not(.dropdown-toggle):not(.btn-sm) {
  @include media-breakpoint-up(xl) {
    &:not(.btn-cta) {
      min-width: $btn-min-width;
    }
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  word-break: break-word;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none !important;
}

.btn-outline-font:hover {
    background-color: $veeam-color-placeholder;
    border-color: $veeam-color-placeholder;
}

.btn-outline-font:not(:disabled):not(.disabled):active,
.btn-outline-font:not(:disabled):not(.disabled).active {
  background-color: $veeam-color-placeholder;
  border-color: $veeam-color-placeholder;
}


.btn-outline-warning:focus,
.btn-outline-warning {
  color: $veeam-color-warning;
}

.btn-outline-warning:hover,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active {
  color: $veeam-color-control-bg;
}

.btn-sm {
  border-width: $border-width;

  &__spacer {
    @include media-breakpoint-up(md) {
      flex: initial;
    }

    flex: 1;
    margin-bottom: 1.2rem;
    white-space: nowrap;
    margin-right: 1.2rem;
  }
}

.btn-cta {
  @include media-breakpoint-up(xl) {
    min-width: $spacer * 28;
  }

  flex: 1;
  width: 100%;
}

.btn-secondary {
  color: $veeam-color-border-focus;
}

.btn.btn-link {
  color: $veeam-color-main;
  text-decoration: none;
  padding: 0;
  border: 0;
  text-align: left;
  vertical-align: baseline;

  &:active,
  &:focus {
    background-color: transparent;
    border: none;
  }


  &.btn-link-cta {
    @include media-breakpoint-down(lg) {
      text-align: left;
    }
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-weight: bold;
    position: relative;

    &::after {
      @include pseudo-element {
        width: 24px;
        height: 24px;
        background: url('//img.veeam.com/careers/icons/sprites.png') -62px -10.5px;
      }
    }

    &.btn-link-cta-light {
      color: $veeam-color-light-green;

      &::after {
        position: relative;
        left: 8px;
        top: 5.3px;
        background: url('//img.veeam.com/careers/img/img_ru/arrow-right-light-green.png') no-repeat;
      }
    }
  }

  &:hover {
    background: none;
  }
}

.btn-back {
  position: relative;
  text-align: left;
  border-bottom: $border-width solid $veeam-color-disabled;
  border-radius: 0;
  margin-bottom: $card-spacer-y;
  padding-bottom: $navbar-padding-x * 2;
  padding-left: 2rem !important;

  &::after {
    @include pseudo-element {
      position: absolute;
      width: 24px;
      height: 24px;
      background: url('//img.veeam.com/careers/icons/arrow_back_gray.png') no-repeat;
      left: -6px;
    }
  }
}

.btn-wrapper {
  margin-bottom: 20rem;
}

.btn.dropdown-toggle {
  border-width: $input-border-width;
}

