.card {
  background: transparent;
  margin-bottom: $card-spacer-y;
  z-index: $card-z-index;
  background: $veeam-color-control-bg;

  .btn-link-cta {
    padding: 0;
  }

  .card-header {
    align-items: flex-start;

    span + span {
      margin-left: $spacer;
    }
  }


  &.card-table {
    display: flex;
    min-width: 100%;

    @include media-breakpoint-up(lg) {
      flex-flow: row;
      min-width: 45%;

      .card-header {
        min-width: 20rem
      }
    }
  }


  &.card-cta {
    border: 0;

    .card-body {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        padding-left: 0;
        padding-right: 0;

        .btn {
          margin-bottom: 0 !important;
        }
      }

      .btn {
        margin-bottom: $card-spacer-y;
      }
    }
  }

  &.card-banner {
    .card-header {
      padding-bottom: 0;
    }

    .card-footer {
      justify-content: flex-end !important;
    }

    &.card-banner-main {
      min-height: 100%;

      &.card-banner-with-pattern {
        @include media-breakpoint-up(md) {
          background: url('//img.veeam.com/careers/icons/banner_pattern.png');
          background-repeat: no-repeat;
          background-position: bottom;
        }
      }
    }
  }

  &-sm {
    @include media-breakpoint-up(md) {
      .card-header {
        align-items: flex-end;
      }
    }
    margin-bottom: 0;

    .card-header,
    .card-body,
    .card-footer {
      padding: $card-spacer-y - 1 $card-spacer-x - 1;
    }

    & + .card-sm {
      margin-top: 3rem;
      margin-bottom: 0;
    }
  }

  &-header-fixed {
    height: $spacer * 5;
  }

  &-pattern-bg {
    background: url("//img.veeam.com/careers/banners/banners_ru/pattern_cta.png");
    background-position: top -10px right -30px;
    background-color: $veeam-color-control-bg;
  }

  &-scroll {
    @include media-breakpoint-down(sm) {
      overflow-x: scroll;
      margin-bottom: -3rem;

      &::-moz-viewport-scroll {
        display: none;
      }

      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }

  &.card-basis {
    @include media-breakpoint-up(md) {
      flex-basis: 30%;
      max-width: 50%;
    }

    @include media-breakpoint-up(lg) {
      flex-basis: 30%;
      max-width: 30%;
    }

    flex-basis: auto;
  }

  &.card-basis-sm {
    @include media-breakpoint-up(md) {
      flex-basis: 20%;
      max-width: 20%;
    }

    flex-basis: auto;
  }

  &.card-basis-lg {
    @include media-breakpoint-up(md) {
      flex-basis: 45%;
    }

    flex-basis: auto;
  }

  &.card-no-spacer-x {
    .card-header,
    .card-body,
    .card-footer {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.card-with-caption {
    @include media-breakpoint-up(md) {
      min-height: auto;
      max-width: 80%;

      .card-header {
        align-items: flex-start;
        flex: 1 0 8rem
      }
    }

    height: 100%;
    min-height: 46rem;
  }

  &.card-collapse {
    .btn-link {
      position: relative;
      padding-left: $spacer * 1;
      font-weight: bold;

      &[aria-expanded="true"]::after {
        content: '';
        display: block;
        background: url(//img.veeam.com/careers/landing/img/icons/accordion-opened.png);
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        top: 0;
        left: -30px;
      }

      &[aria-expanded="false"]::before {
        content: '';
        display: block;
        background: url(//img.veeam.com/careers/landing/img/icons/accordion-closed.png);
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        top: 0;
        left: -30px;
      }
    }

    .card-body {
      padding-left: $spacer * 4;
    }
  }

  &.card-fixed {
    @include media-breakpoint-up(xl) {
      min-width: 30%;
      max-width: 35%;
    }

    @include media-breakpoint-up(xl) {
      max-height: 100%;
      min-height: 100%;
    }
  }

  &.card-double {
    @include media-breakpoint-up(md) {
      flex-flow: row;
      min-width: 63%;
    }

    @include media-breakpoint-up(xl) {
      flex-grow: 2;
      min-width: 64%;
    }

    .card {
      overflow: hidden;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 0;
      border: 0;

      &-footer {
        justify-content: flex-end;
      }
    }

    .card-image {
      @include media-breakpoint-up(md) {
        border-top-right-radius: 0;
        border-bottom-left-radius: $card-border-radius;
      }

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: 0;


      img {
        @media(max-width: 400px) {
          height: 300px;
        }

        @media(max-width: 768px) {
          height: 360px;
        }

        min-height: 100%;
      }

      & + .card {
        @include media-breakpoint-up(xl) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        margin-left: -1px;
      }
    }

    .card-image,
    .card-banner {
      @include media-breakpoint-up(xl) {
        max-height: 100%;
        min-height: 100%;
      }

      .card-footer {
        justify-content: flex-end !important;
      }
    }
  }

  &-columns {
    column-count: 5;
    margin-left: $card-columns-spacer;
    margin-right: $card-columns-spacer;
    column-gap: $card-columns-gap;

    .card {
      margin-bottom: $card-columns-gap;
    }
  }
}

a.card-no-hover:not(.bg-info) {
  color: $veeam-color-control-bg;
}

a.card-double.card-no-hover {
  .display-3 {
    color: $veeam-color-font;
  }
}

a.card-no-hover:hover,
a.card-body:hover{
  text-decoration: none !important;
}


a.card-clickable {
  &:hover {
    text-decoration: none;
  }

  &.card-banner:hover {
    color: $veeam-color-control-bg;
  }

  &.card-news,
  &.card-stories {
    color: $veeam-color-font;

    &:hover {
      color: $veeam-color-font;
    }
  }

}
