.map-wrapper {
  position: relative;


  .map-legend {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.8s;
    position: absolute;
    background: $veeam-color-control-bg;

    &-emea {
      right: 0;
      top: 0;
    }

    &-apj {
      right: 0;
      bottom: 0;
    }

    &-na {
      left: 0;
      top: 0;
    }

    &-latam {
      left: 0;
      bottom: 0;
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
}

path {
  fill: #cdcdcd;
  transition: fill .3s ease-in-out;
}

.fill-green {
  & > * {
    fill: #00b336;
  }

  & > * > * > path {
    fill: #00b336;
  }
}