.nav {
  &-tabs {
    border-bottom: 0;
  }

  &.nav-tabs-tags {
    border-bottom: 0;

    .nav-item {
      border-radius: 18px;
      background-color: transparent;
      border: $border-width solid $veeam-color-font;
      color: $veeam-color-font;
      padding-top: $input-btn-padding-y / 2;
      padding-bottom: $input-btn-padding-y / 2;
      flex-flow: row;
      white-space: nowrap;
      margin-bottom: 0.2rem;

      &.active {
        background-color: $veeam-color-disabled;
        color: $veeam-color-control-bg;
        border: $border-width solid $veeam-color-disabled;
      }

      & + .nav-item {
        margin-top: 1rem;

        @include media-breakpoint-up(md) {
          margin-left: $spacer *1.2;
          margin-top: 0;
        }
      }
    }
  }
}
