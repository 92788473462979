.navbar-nav {
  @include media-breakpoint-up(lg) {
    align-items: center;
  }

  /*& + .navbar-nav {
    margin-left: $navbar-padding-x * 3;
  }*/

  font-weight: bold;

  .nav-link {
    @include media-breakpoint-up(lg) {
      padding: $nav-link-padding-y * 1.5 0;
      margin-right: $nav-link-padding-x + 1rem;
    }

    @include media-breakpoint-down(md) {
      & > .btn-primary {
        background: transparent;
        border: 0;
        color: inherit;
        text-align: left;
        padding-left: 0;
      }
    }

    padding: 0;
    text-transform: uppercase;
    color: $veeam-color-placeholder;
  }

  /*.site-switcher > .nav-link::after {
    content: none;
  }*/
}

.navbar-toggler-icon {
  display: none;
}

.navbar-light .navbar-toggler {
  border-color: transparent;
}

.navbar-light .navbar-toggler-icon {
  background: none;
}

.navbar-toggler {
  align-items: center;
  display: flex;
  height: $icon-sprites-width;
  width: $icon-sprites-width;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: $icon-sprites-width;
    height: $icon-sprites-height;
    top: 2px;
    background: url('//img.veeam.com/careers/icons/sprites_mobile.png') -5px -136px;
  }


  &.collapsed::before {
    background: url('//img.veeam.com/careers/icons/sprites_mobile.png')  -146px -14px no-repeat;
    top: 3px;
  }
}

.navbar-toggler:focus {
  outline: none;
  border-color: transparent;
}

.navbar-collapse {
  &.navbar-collapse-menu.collapse,
  &.navbar-collapse-menu {
    position: fixed;
    background: $veeam-color-control-bg;
    top: $mobile-menu-height;
    left: 0;
    right: 0;
    bottom: 0;
    padding: $nav-link-padding-y * 1.9 0;
    color: $veeam-color-font;

    .container {
      display: block;
    }
    .dropdown-menu.show {
      overflow-y: auto;
    }
  }

  .navbar-nav {
    @include media-breakpoint-up(lg) {
      color: $veeam-color-placeholder;
    }

    .nav-link {
      padding: $nav-link-padding-y * 1.5 0;
    }

    color: $veeam-color-border-focus;
  }
}

.navbar {
  @include media-breakpoint-up(lg) {
    padding: $navbar-padding-y / 2 0;
  }

  padding-left: 0;
  padding-right: 0;
}

.navbar-close {
  display: none;
}
