.carousel-inner {
  @media (max-width: 320px) {
    min-height: 50rem;
  }
  z-index: 110;
}

.carousel-title {
  min-width: 50rem;
  max-width: 50rem;
  text-align: left;

  .carousel-indicators {
    display: none;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none;
}

.carousel-indicators {
  bottom: -5rem;

  li {
    width: 8.8rem;
    height: 0.6rem;
    background-color: $veeam-color-disabled;

    &.active {
      background-color: $veeam-color-light-green
    }
  }
}

.carousel-narrow {
  @include media-breakpoint-up(md) {
    .carousel-indicators {
      bottom: -7rem;
    }
  }

  .carousel-indicators {
    bottom: -5rem;
  }
}

.carousel.carousel-awards {
  .carousel-item {
    @include media-breakpoint-up(md) {
      min-height: 42rem;
    }
    min-height: 70rem;
  }
}

.carousel-photos {
  .carousel-control-prev {
    @include media-breakpoint-up(xl) {
      background: linear-gradient(270deg, #004550 0%, rgba(0, 69, 80, 0) 100%);
    }
    display: block;
    height: $social-photo-height;
    opacity: 0.9;
    top: 80px;
    left: -1px;
    transform: rotate(-180deg);
    z-index: $mobile-menu-z-index;
  }
  .carousel-control-next {
    @include media-breakpoint-up(xl) {
      background: linear-gradient(270deg, #004550 0%, rgba(0, 69, 80, 0) 100%);
    }
    display: block;
    height: $social-photo-height;
    opacity: 0.9;
    top: 80px;
    z-index: $mobile-menu-z-index;
  }

  .carousel-indicators {
    bottom: -5rem;
  }

}

.carousel-vacancies {
  .carousel-control-prev,
  .carousel-control-next {
    @include media-breakpoint-up(md) {
      top: 0;
    }

    position: relative;
    display: inline-block;
    color: $veeam-color-main;
    width: auto;
    opacity: 1;
    padding: $spacer;
    top: 2rem;
    z-index: 110;

    &:hover {
      color: darken($veeam-color-main, 10%);
    }
  }

  .carousel-control-prev::after {
    content: "BACK";
    font-weight: 600;
  }

  .carousel-control-next::before {
    content: "NEXT";
    font-weight: 600;
  }

  .carousel-vacancies.carousel-control-prev {
    margin-right: $spacer;
  }

  .carousel-vacancies-inner {
    @media(min-width: 375px) {
      min-height: 54rem;
    }

    @include media-breakpoint-up(md) {
      min-height: 30rem;
    }

    min-height: 67rem;

  }

  .carousel-vacancies-inner-md {
    @media(min-width: 375px) {
      min-height: 54rem;
    }

    @include media-breakpoint-up(md) {
      min-height: 32rem;
    }

    min-height: 46rem;

  }

  .carousel-vacancies-inner-lg {
    @media(min-width: 375px) {
      min-height: 63rem;
    }

    @include media-breakpoint-up(md) {
      min-height: 46rem;
    }

    min-height: 68rem;

  }

  .carousel-indicators {
    display: none;
  }

  &.carousel-vacancies-ru {
    .carousel-control-prev::after {
      content: "НАЗАД";
      font-weight: 600;
    }

    .carousel-control-next::before {
      content: "ДАЛЕЕ";
      font-weight: 600;
    }
  }
}
