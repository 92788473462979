$card-spacer-y: 2rem;

.card {
  .card-header,
  .card-footer {
    display: flex;
    border: 0;
  }


  .card-footer {
    justify-content: space-between;

    .location {
      display: flex;

      span + span {
        @include media-breakpoint-up(sm) {
          margin-left: $spacer;
        }
      }
    }
  }
}
