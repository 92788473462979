.card-deck {
  @include media-breakpoint-up(md) {
    flex-flow: row wrap;
  }

  flex-flow: column;

  .card {
    margin-bottom: 3rem;
  }

  &.card-deck-numbers {
    .card {
      min-width: 30rem;

      .card-body {
        padding-left: 0;
      }
    }
  }

  &.card-deck-banners {
    @include media-breakpoint-up(md) {
      .card:not(.card-double) {
        min-width: 30%;
      }
    }

    @media (width: 1024px) {
      .card-double {
        min-height: 44rem;
      }
    }

    @include media-breakpoint-up(xl) {
      flex-flow: row wrap;
    }

    flex-flow: column;
  }

  &.card-deck-partners {
    .card {
      @include media-breakpoint-up(md) {
        min-width: 16rem;
        max-width: 16rem;
        margin-right: 0;
      }

      min-width: 40%;
      width: 40%;
      margin-bottom: 3rem;
      margin-right: 3rem;

      & + .card {
        margin-top: 0;
      }

      .card-body {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &.card-deck-success {
    @include media-breakpoint-up(md) {
      .card {
        min-width: 45%;
      }
    }

    @include media-breakpoint-up(lg) {
      .card {
        min-width: auto;
      }
    }
  }

  &.flex-row.justify-content-center {
    .card {
      min-width: 30%
    };
  }

  &.card-deck-fixed {
    .card {
      @include media-breakpoint-up(md) {
        min-width: 30%;
        max-width: 35%;
      }
    }
  }

  & + .card-deck.block-spacer {
    margin-top: 0;
  }
}
