#root {
  width: 100%;
}

a {
  color: $veeam-color-main;
}

.careers-app {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.bg-green,
.bg-dark,
.bg-danger {
  color: $veeam-color-control-bg;
}

a.bg-dark:hover {
  background-color: #004550 !important;
}

a.bg-danger:hover {
  background-color: #005f4b !important;
}

a.bg-green:hover {
  background-color: #00B492 !important;
}

a.bg-info:hover {
  background-color: $veeam-color-control-bg !important;
}

.container-fluid {
  position: relative;
}

.container-main {
  /*@media (min-width: 1600px) {
    background: url("//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png") no-repeat;
    background-position: 0 43%;
  }*/
}

.container-spacer {
  @include media-breakpoint-up(lg) {
    padding-top: 6rem;
    padding-bottom: 10rem;
  }
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;

  & + .container-spacer {
    padding-top: 0;
  }
}

.container-spacer-lg {
  @include media-breakpoint-up(lg) {
    padding-top: 18rem;
    padding-bottom: 10rem;
  }

  padding-top: 10rem;
  padding-bottom: 5rem;
}

.block-spacer {
  @include media-breakpoint-up(md) {
    margin-top: $spacer * 6;
    margin-bottom: $spacer * 4;
  }
  margin-top: $spacer * 2.4;
  margin-bottom: $spacer * 2.4;
}

.block-spacer-mobile {
  @include media-breakpoint-down(md) {
    margin-top: $spacer * 2.4;
    margin-bottom: $spacer * 2.4;
  }
}

.content-wrapper {
  margin-bottom: 6rem;
}

.block-spacer-top {
  margin-top: 6rem;
}

.header-spacer {
  margin-bottom: $spacer * 4.8;
}


.header-custom-spacer {
  margin-bottom: $spacer * 3.2 !important;
}

.header-custom-spacer-md {
  margin-bottom: $spacer * 1.2 !important;
}

.banner-spacer {
  @include media-breakpoint-up(md) {
    padding-top: 18rem;
    padding-bottom: 8rem;

  }
  padding-top: 10rem;
  padding-bottom: 5rem;
}

.banner-spacer-md {
  @include media-breakpoint-up(md) {
    padding-top: 12rem;
    padding-bottom: 12rem;

  }
  padding-top: 10rem;
  padding-bottom: 5rem;
}

.block-spacer-bottom {
  margin-bottom: 10rem;
}

.z-index-overflow {
  z-index: 200;
}

.block-news {
  @include media-breakpoint-down(md) {
    align-items: flex-start !important;

    .btn-link-cta {
      padding: 0;
    }
  }
}

.shareaholic-share-buttons-container {
  max-width: 100%;
}

:focus {
  outline: none;
}

.container-fluid {
  padding: 0;

  &.container-main {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.error-message {
  @include media-breakpoint-up(lg) {
    margin-top: $menu-height;
  }

  height: 100%;
  margin-top: $mobile-menu-height;

}
.mdc-checkbox__checkmark-path {
  fill: none;
}

.text-pre-wrap {
  white-space: pre-wrap;
}
//tmp styles

