$header-mobile-height: 6.4rem;
$grid-spacer: -25rem;

.grid-wrapper {
  @include media-breakpoint-down(md) {
    margin: $header-mobile-height $grid-spacer;
  }

  margin: 0 $grid-spacer;

  .card {
    @include media-breakpoint-down(md){
      width: 45%;
    }
    width: 452px;
    margin-left: 26px;
    will-change: transform, opacity, left, top;
  }
}

.button-wrapper {
  bottom: 10px;
  right: 10px;
  z-index: 1500;
}

.content-grid-wrapper {
  height: 100vh;
  overflow: hidden;
}

.layout-global {
  overflow: hidden !important;
}
