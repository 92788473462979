$header-height: 7rem;
$header-height-lg: 10rem;
$dropdown-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05), 0 6px 12px rgba(0, 0, 0, 0.07);
$dropdown-width: 32rem;

.header {
  @include media-breakpoint-up(md) {
    border-bottom: $border-width solid $veeam-color-disabled;
  }

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: $mobile-menu-z-index;

  .nav-link {
    align-items: center;
  }

  .dropdown-section {
    @include media-breakpoint-up(lg) {
      max-width: $dropdown-width;

      h3 {
        padding: $dropdown-item-padding-x / 2;
      }
    }

    .dropdown-item {
      @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &.header-desktop {
    transition: background .2s ease-in;

    .container,
    .row,
    .col,
    .navbar,
    .navbar-nav:nth-child(2) .nav-link,
    .navbar-nav:nth-child(2) .dropdown {
      position: static;
    }

    .navbar-expand {
      justify-content: space-between;
    }

    .navbar-nav:nth-child(2) {
      .dropdown-menu.show {
        @include media-breakpoint-up(md) {
          top: $header-height-lg;
        }
        border: 0;
        box-shadow: $dropdown-box-shadow;
        padding-top: $dropdown-padding-y * 4;
        padding-bottom: $dropdown-padding-y * 4;;
        top: $header-height;
      }
    }

    .navbar-nav:nth-child(1) {
      .dropdown-menu.show {
        top: $header-height;
        margin-top: 0;
        border: 0;
        box-shadow: $dropdown-box-shadow;
      }
    }
  }

  &.header-mobile {
    position: fixed;
    z-index: $mobile-menu-z-index;

    .navbar-nav {
      /*.site-switcher {
        .dropdown-toggle {
          color: $veeam-color-main;
          padding-right: $icon-sprites-width + $spacer;
        }
      }*/

      .nav-link {
        color: inherit;
      }


      .dropdown:not(.dropdown-search) > .dropdown-toggle::after {
        content: none !important;
      }
    }

    .navbar-collapse-menu {
      /*.dropdown-toggle::after {
        @include triangle-outlined(relative, 'right', $veeam-color-main);
        margin-right: 15px;
        width: $icon-width;
        height: $icon-width;
      }*/
    }

    .navbar-nav-search {
      .nav-link::after {
        @include pseudo-element {
          width: 24px;
          height: 24px;
          background: url('//img.veeam.com/careers/icons/sprites_mobile.png') -146px -54px;
        }
      }
    }


    .dropdown-menu.show {
      box-shadow: $dropdown-box-shadow;
      position: fixed;
      top: $mobile-menu-height;
      left: 0;
      right: 0;
      width: 100%;
      border: 0;
      padding: $dropdown-item-padding-y $dropdown-item-padding-x - .4;
    }

    .navbar-collapse-menu.collapse {
      .dropdown-menu.show {
        bottom: 0;
      }
    }
  }
}

/*header:not(.bg-transparent) {
  .header.header-desktop {
    .dropdown-toggle {
      &[aria-expanded="false"]::after {
        @include triangle-outlined(relative, 'down', $veeam-color-placeholder);
      }

      &[aria-expanded="true"]::after {
        @include triangle-outlined(relative, 'up', $veeam-color-placeholder);
      }
    }
  }
}*/
