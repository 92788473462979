$theme-colors:(
  "primary":    $veeam-color-main,
  "secondary":  $veeam-color-light-green,
  "green":      $veeam-color-green,
  "success":    $veeam-color-main,
  "info":       $veeam-color-control-bg,
  "warning":    $veeam-color-warning,
  "danger":     $veeam-color-sapphire,
  "light":      $veeam-color-body,
  "dark":       $veeam-color-footer-bg,
  "muted":      $gray-100,
  "blockquote":  $veeam-color-sub-header,
  "placeholder":   $veeam-color-placeholder,
  "font":   $veeam-color-font
);

$font-path-s3: '/fonts/';
$font-family-opensans: (
        'Open Sans',
        'Droid Sans',
        'Helvetica Neue',
        Helvetica, Arial,
        sans-serif
);
$font-family-guardian: (
        'Guardian Sans Cy Web',
        sans-serif
);
$font-family-guardian-text: (
        'Guardian Text Sans Cy Web',
        sans-serif
);

$font-family-guardian-header: (
        'Guardian Text Sans Cy',
        sans-serif
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

$container-max-widths: (
        sm: 540px,
        md: 740px,
        lg: 960px,
        xl: 1200px
);

$sizes: map-merge(
        (
                25: 25%,
                35: 35%,
                50: 50%,
                65: 65%,
                80: 80%,
                100: 100%,
                auto: auto
        ),
        $sizes
);

$btn-font-size: 1.6rem;
$font-size-base: 1.6rem;
$font-size-sm: 1.4rem;
$h1-font-size: 5rem;
$h2-font-size: 4rem;
$h3-font-size: 2.8rem;
$h4-font-size: 2.4rem;
$h5-font-size: $font-size-base;
$headings-margin-top: $spacer * 4.8;
$headings-margin-bottom: $spacer * 4.8;

$display1-size:  6rem;
$display2-size:  5.5rem;
$display3-size:  4rem;
$display4-size:  2.8rem;
$display-number-size: 14rem;
$figure-caption-font-size: 2rem;

$border-width: 1px;
$input-btn-border-width: $border-width;
$input-btn-padding-y: 1.2rem;
$input-btn-padding-x: 2.4rem;
$input-box-shadow: none;
$dropdown-box-shadow: none;

$input-btn-font-size: $font-size-base;
$input-font-size: $input-btn-font-size;

$btn-border-radius: 2.6rem;
$btn-border-radius-sm: 2.6rem;
$btn-border-width: $input-btn-border-width * 2;
$btn-font-size-sm: $font-size-base;
$btn-font-size-lg: $font-size-base;
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-min-width: 21.2rem;
$btn-padding-x-sm: 1.6rem;

$blockquote-size: 3.2rem;
$blockquote-height: 50rem;
$blockquote-font-size: $font-size-base + 0.8;
$blockquote-small-font-size: $font-size-base;

$border-color: $veeam-color-border;
$border-radius: 0;
$input-border-radius: $border-radius;

$card-border-radius: 1rem;
$card-cap-bg: transparent;
$card-spacer-y: 3rem;
$card-spacer-x: 3rem;
$card-columns-spacer: -25rem;
$card-columns-gap: 26px;

$dropdown-border-radius: 0;
$dropdown-spacer: 0;
$dropdown-min-width: 100%;

$navbar-padding-y: $spacer * 2;

$dropdown-font-size: $font-size-base;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x / 2;
$input-border-color: $veeam-color-placeholder;

$input-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + 4px);
$input-color: $veeam-color-sub-header;
$input-btn-focus-box-shadow: none;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$dropdown-padding-y: 2rem;
$dropdown-item-padding-y: $spacer * 1.2;
$dropdown-item-padding-x: 2.4rem;


$nav-link-padding-x: 2rem;
$nav-link-padding-y: $spacer;

$form-wrapper-width-lg: 81rem;
$form-wrapper-width-md: 61rem;
$search-input-width: 44rem;
$form-wrapper-spacer: 8rem;

$custom-checkbox-indicator-border-radius: 0;
$custom-control-indicator-size: 2rem;
$custom-switch-width: $custom-control-indicator-size;
$custom-control-indicator-checked-bg: $veeam-color-border;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;
$component-active-bg: $veeam-color-control-bg;
$custom-control-indicator-checked-bg: $component-active-bg;

$menu-height: 10.3rem;
$mobile-menu-height: 6.4rem;
$mobile-menu-z-index: 200;
$card-z-index: 190;

$controls-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);


$icon-sprites-width: 2.4rem;
$icon-sprites-height: 2.4rem;
$icon-width: 1rem;
$icon-height: 1rem;

$social-photo-height: 30rem;

$banner-height: 634px;


