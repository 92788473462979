.custom-control {
  padding: $input-padding-y $input-btn-padding-x * 1.5;
  background-color: $veeam-color-control-bg;

  &:hover {
    background-color: $veeam-color-label;
  }

  .custom-control-label {
    padding-left: $spacer * 1.6;

    &::before {
      border: $border-width solid $veeam-color-placeholder;
    }
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  width: 20px;
  height: 20px;
  background: url('//img.veeam.com/careers/icons/sprites.png') -146px -80px;
}

.custom-control.custom-checkbox > .custom-control-label {
  width: 100%;
}
