@mixin headings-md($header) {
  @if $header == 'h1' {
    h1,
    .h1 {
      font-size: 2rem;
    };
  } @else if $header == 'h2' {
    h2,
    .h2 {
      font-size: 2rem
    }
  } @else if $header == 'h3' {
    h3,
    .h3 {
      font-size: $h3-font-size - 0.8;
    };
  } @else if $header == 'display-1' {
    .display-1 {
      font-size: $h2-font-size - 1;
    };
  }  @else if $header == 'display-2' {
    .display-2 {
      font-size: $h3-font-size + 0.4;
    }
  } @else if $header == 'display-3' {
    .display-3 {
      font-size: $h3-font-size;
    }
  } @else if $header == 'display-number' {
    .display-number {
      font-size: $h1-font-size;
      line-height: 1.2;
    }
  }
}


@mixin disable-native-icons {
  border: 0
}

@mixin pseudo-element {
  content: "";
  display: inline-block;
  @content
}


@mixin prefix($prop, $value) {
  -webkit-#{$prop}: #{$value};
  -moz-#{$prop}: #{$value};
  -ms-#{$prop}: #{$value};
  -o-#{$prop}: #{$value};
  #{$prop}: #{$value};
}

@mixin bg-with-picture($src) {
  background: url($src) no-repeat;
  background-size: cover;
  background-color: $veeam-color-footer-bg;
}

@mixin triangle-outlined($position, $direction, $color) {
  content: '';
  display: block;
  position: $position;
  border: solid $color;
  border-width: 0 2px 2px 0;
  padding: 4px;
  @content;

  @if ($direction == 'up') {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  } @else if ($direction == 'down') {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  } @else if ($direction == 'left') {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  } @else if ($direction == 'right') {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}
