$video-height-md: 450px;
$video-height-xl: 540px;

.bg-* {
  position: relative;
}

.bg-transparent {
  @include media-breakpoint-up(lg) {
    .header {
      border-bottom-color: transparent;
    }

    .nav-link {
      color: $veeam-color-control-bg !important;

      &::after {
        background: url(//img.veeam.com/careers/icons/sprites.png) -40px -147px;
      }
    }
  }

  .dropdown-search {
    .nav-link::after {
      background: url(//img.veeam.com/careers/icons/icon_search_white.png);
    }
  }

  .bg-fade {
    background-color:  $veeam-color-footer-bg;
  }

}

.bg-white {
  .navbar-nav {
    .nav-link {
      color: $navbar-light-color !important;
    }
  }
}

.bg-homepage {
  @include media-breakpoint-up(md) {
    @include bg-with-picture("//img.veeam.com/careers/banners/banners_ru/banner_header.png");
  }
}

.bg-homepage {
  @include media-breakpoint-up(md) {
    @include bg-with-picture("//img.veeam.com/careers/banners/banners_ru/banner_header.png");
  }
}


.bg-video {
   display: none;
  @include media-breakpoint-up(xl) {
    display: block;
    position: absolute;
    min-width: 100%;
    background-blend-mode: saturation;
    opacity: 0.6;
  }
}

.bg-with-video {
  @include media-breakpoint-up(xl) {
    min-height: $video-height-md;
  }

  @media (min-width: 1440px) {
    min-height: $video-height-xl;
  }

  position: relative;
  overflow: hidden;
}

.bg-about-company {
  @include media-breakpoint-up(md) {
    @include bg-with-picture("//img.veeam.com/careers/banners/banners_ru/banner_company.png");
  }
}

.bg-about-prague {
  @include media-breakpoint-up(md) {
    @include bg-with-picture("//img.veeam.com/careers/banners/banners_ru/banner_prague.png");
  }
}

.bg-info,
.bg-info:hover{
  color: $veeam-color-font;
}


