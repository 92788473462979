$dropdown-icon-width: 1.6rem;
$dropdown-icon-height: 1.6rem;

.dropdown-menu {
  padding: 0;

  .container {
    display: block;
  }

  .dropdown-section {
    @include media-breakpoint-up(xl) {
      margin-bottom: 6rem;
    }

    h3 {
      margin-bottom: $dropdown-padding-y;
    }

    li {
      margin-bottom: 0;
    }

    & + .dropdown-section {
      @include media-breakpoint-up(xl) {
        margin-top: 0;
      }
    }

    margin-bottom: $card-spacer-y * 2;
  }

  .dropdown-item {
    padding-left: $dropdown-item-padding-x / 2;
    padding-right: $dropdown-item-padding-x / 2;
    white-space: normal;
    background-color: $veeam-color-control-bg;

    /*@include media-breakpoint-up(md) {
      padding-left: $dropdown-item-padding-x / 2;
      padding-right: $dropdown-item-padding-x / 2;
    }

    padding-left: 0;
    padding-right: 0;
    white-space: normal;*/


    &:hover,
    &:active {
      @include media-breakpoint-up(lg) {
        background-color: $veeam-color-label;
      }
      background-color: transparent;
      color: $veeam-color-font;
    }
  }
}

.dropdown-toggle {
  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
  }

  /*&[aria-expanded="false"]::after {
    @include media-breakpoint-up(lg) {
      @include triangle-outlined(relative, 'down', $veeam-color-placeholder);
      top: -4px;
      bottom: 4px;
      right: -5px;
    }

    @include triangle-outlined(absolute, 'down', $veeam-color-main);
    background: none;
    top: 3px;
    right: 0;
    width: $icon-width;
    height: $icon-width;
  }*/

  /*&[aria-expanded="true"]::after {
    @include media-breakpoint-up(lg) {
        @include triangle-outlined(relative, 'up', $veeam-color-placeholder);
        top: 0;
        right: -5px;
    }

    @include triangle-outlined(absolute, 'up', $veeam-color-main);
    background: none;
    right: 0;
    top: 7px;
    width: $icon-width;
    height: $icon-width;
  }*/

  &::after {
    @include disable-native-icons;
  }

  @include prefix(border-radius, $dropdown-border-radius);
  color: $veeam-color-placeholder;
  display: flex;
  justify-content: space-between;
  border-color: $veeam-color-placeholder;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    color: $veeam-color-placeholder;
  }

  &::after {
    @include disable-native-icons;
    @include media-breakpoint-up(lg) {
      background: url('//img.veeam.com/careers/icons/sprites.png') -147px -111px;
      color: $veeam-color-placeholder;
    }
    background: url('//img.veeam.com/careers/icons/sprites_mobile.png') -190px -9px;
    content: "";
    width: $dropdown-icon-width;
    height: $dropdown-icon-height;
    color: $veeam-color-border-focus
  }


  &.no-icon {
    &::after {
      content: none;
    }
  }

  &.selected {
    background-color: $veeam-color-green !important;
    border-color: $veeam-color-green;
    color: $veeam-color-control-bg;

    &::after {
      background: url('//img.veeam.com/careers/icons/sprites.png') -43px -145px !important;
    }
  }
}


.dropdown-search {
  .nav-link::after {
    background: url(//img.veeam.com/careers/icons/icon_search_gray.png);
  }

  .dropdown-menu {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}
