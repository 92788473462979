$veeam-color-main: #00b336;
$veeam-color-font: #232323;
$veeam-color-caption: #8c8c8c;
$veeam-color-border: #00b336;
$veeam-color-lines: #b3b2b2;
$veeam-color-border-focus: #005f4b;
$veeam-color-control-bg: #fff;
$veeam-color-links: #00b336;
$veeam-color-disabled: #cdcdcd;
$veeam-color-disabled-bg: #f7f7f7;
$veeam-color-disabled-border: #ccc;
$veeam-color-not-available: #ababab;
$veeam-color-danger: #ba0200;
$veeam-color-sapphire: #005f4b;
$veeam-color-label: #f0f0f0;
$veeam-color-body: #f1f1f1;
$veeam-color-gray-block: #e5e5e5;
$veeam-color-green: #00B492;
$veeam-color-warning: #F7941D;
$veeam-color-sub-header: #232323;
$veeam-color-placeholder: #999999;
$veeam-color-gray: #8d8d8d;
$veeam-color-light-green: #93ea20;
$veeam-color-footer-bg: #004550;
$veeam-color-outline-success: #c5c5c5;
$veeam-color-light-green-new: #00E296;

.text-success {
  color: $veeam-color-main;
}
