.img-logo {
  margin-right: $nav-link-padding-x + 1rem;
  width: 20rem;
}


.img-pattern {
  @media (min-width: 1660px) {
    display: inline-block;
    position: absolute;
    z-index: 100;
  }

  display: none;

  &.img-pattern-right-top {
    @media (max-width: 1600px) {
      max-width: 45%;
    }

    right: 0;
    top: -22px;
  }

  &.img-pattern-right-bottom {
    @media (max-width: 1670px) {
      right: 0;
    }
    right: 0;
    bottom: 0
  }

  &.img-pattern-left-top {
    @media (max-width: 1600px) {
      max-width: 45%;
    }

    top: $banner-height * 2;
  }

  &.img-pattern-left-middle {
    left: 0;
    top: 35%;
  }

  &.img-pattern-left-side {
    top: -415px;
  }

  &.img-pattern-left-bottom {
    left: 0;
    top: 386px;
  }

  &.img-pattern-social {
    right: 0;
    top: -331px;
  }
}

.pattern-wrapper {
  position: relative;
}

.icon-flag {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: -$icon-sprites-width * 1.5;
    display: inline-flex;
    width: $icon-sprites-width;
    height: $icon-sprites-height;
  }
}

.flag-ru::after {
  background: url("//img.veeam.com/careers/img/img_ru/flag_ru.png");
}

.flag-cz::after {
  background: url("//img.veeam.com/careers/img/img_ru/flag_cz.png");
}

.img-steps {
  position: absolute;
  right: -26px;
}

.img-legend {
  display: none;
  width: 10rem;
  height: 5rem;
  background: $veeam-color-control-bg;

  &.show {
    display: block;
  }
}

