$display-spacer: 2rem;

.display-number {
  font-size: $display-number-size;
  line-height: 1.4;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-bottom: $headings-margin-bottom;
}

@include media-breakpoint-down(lg) {
  @include headings-md('h1');
  @include headings-md('h2');
  @include headings-md('h3');
  @include headings-md('display-1');
  @include headings-md('display-2');
  @include headings-md('display-3');
  @include headings-md('display-number');

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3 {
    margin-bottom: $headings-margin-bottom / 2;
  }
}


.display-1,
.display-2,
.display-3,
.display-4 {
  margin-bottom: $headings-margin-bottom;

  & + ul {
    margin-top: 0;
  }
}

.display-1 {
  font-weight: bold;
}



.display-digits {
  @include media-breakpoint-down(lg) {
    font-size: 5rem;
  }

  font-size: 8rem;
}

h3,
.h3 {
  font-weight: $headings-font-weight - 100;
}


.text-type {
  min-height: $display1-size + 4;
}

.text-slogan {
  font-size: 2rem;
}


.text-with-icon {
  padding-left: 3rem;
  position: relative;

  &.text-warning::before {
    content: "";
    position: absolute;
    width: 19px;
    height: 33px;
    left: 0;
    top: -6px;

    background: url('//img.veeam.com/careers/icons/sprites.png') -150px -12px;
  }
}


//only for styles that come from server
.h2.col-md-12 {
  margin-top: 6rem;
}
